.hp-info-block {
  overflow: hidden;
  background-color: #fff;
  padding-top: var(--space-40);
}
.hp-info-block__wrapper {
  display: flex;
}
.hp-info-block__wrapper--app {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.hp-info-block__wrapper--app::before {
  content: "";
  width: calc(100% + 2.5rem);
  display: block;
  position: absolute;
  height: 100%;
  background-color: var(--color-blue-100);
  z-index: 0;
}
@media (min-width: 1024px) {
  .hp-info-block__wrapper--app::before {
    height: 80%;
  }
}
.hp-info-block__wrapper--app .hp-info-block__img {
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .hp-info-block__wrapper--app .hp-info-block__img {
    width: 50%;
    max-width: 500px;
    top: 0;
  }
}
.hp-info-block__wrapper--app .hp-info-block__title {
  margin-top: var(--space-32);
  margin-bottom: var(--space-64);
}
@media (min-width: 768px) {
  .hp-info-block__wrapper--app .hp-info-block__title {
    max-width: 75%;
  }
}
.hp-info-block__wrapper--app .hp-info-block__content {
  color: var(--color-blue-1100);
  padding-inline: 1rem;
  padding-top: 1rem;
}
@media (min-width: 768px) {
  .hp-info-block__wrapper--app .hp-info-block__content {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .hp-info-block__wrapper--app {
    margin-inline: 120px;
  }
}
.hp-info-block__wrapper--banner {
  padding-block: var(--space-40);
  gap: 1rem;
  justify-content: flex-end;
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .hp-info-block__wrapper--banner {
    flex-direction: row;
  }
  .hp-info-block__wrapper--banner > div {
    width: 50%;
  }
}
.hp-info-block__wrapper--banner .hp-info-block__title {
  margin-bottom: var(--space-32);
}
@media (min-width: 768px) {
  .hp-info-block__wrapper--banner .hp-info-block__img {
    position: relative;
    width: 45%;
  }
  .hp-info-block__wrapper--banner .hp-info-block__img img {
    position: absolute;
    left: 17%;
    width: auto;
    bottom: 0;
    border-radius: var(--radius-img);
  }
}
@media (min-width: 1024px) {
  .hp-info-block__wrapper--banner .hp-info-block__img {
    width: 50%;
  }
}
.hp-info-block__img img {
  width: 100%;
}
.hp-info-block__content li {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .hp-info-block__content li {
    max-width: 355px;
  }
}
@media (max-width: 767px) {
  .hp-info-block__cta {
    display: block;
  }
}